// React
import { useState } from "react"

// Framer Motion
import StaggerChildren from "../FramerComponents/StaggerChildren"
import FadeInFeature from "../FramerComponents/FadeInFeature"

// Components
import ChallengeTabs from "./Challenges/ChallengeTabs"
import Challenge from "./Challenges/Challenge"
import Learning from "./Challenges/Learning"
import Solution from "./Challenges/Solution"



const Challenges = ({info}) => {

	// ChallengeTab State
	const [selectedChallengeTab, setSelectedChallengeTab] = useState(0)

	// Info
	const challenge = info.challenges[selectedChallengeTab].details[0]
	const solution = info.challenges[selectedChallengeTab].details[1]
	const learning = info.challenges[selectedChallengeTab].details[2]


	return (
		<>
			<FadeInFeature>
				<ChallengeTabs info={info} selectedChallengeTab={selectedChallengeTab} setSelectedChallengeTab={setSelectedChallengeTab} />
			</FadeInFeature>
			<StaggerChildren key={selectedChallengeTab}>
				

				{/* Challenge */}
				<Challenge info={info} challenge={challenge} />

				{/* Solution */}
				<Solution info={info} solution={solution} />

				{/* Learning */}
				<Learning info={info} learning={learning} />

			</StaggerChildren>
		</>
	)
}
export default Challenges