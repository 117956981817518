// React
import { useState } from "react"

// Material UI
import { Box, Stack, Typography } from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch'

// Framer Motion
import StaggerChildren from "../FramerComponents/StaggerChildren"
import FadeInFeature from "../FramerComponents/FadeInFeature"

// Components
import Tabs from "./Tabs"
import Features from "./Features"
import Role from "./Role"
import Challenges from "./Challenges"
import TechStack from "./TechStack"





const Details = ({info}) => {

	// // State
	const [selectedTab, setSelectedTab] = useState("features")



	return (
		<Stack direction="column">

				<Tabs info={info} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

				{selectedTab === "features" && <Features info={info} />}

				{selectedTab === "role" && <Role info={info} />}

				{selectedTab === "challenges" && <Challenges info={info} />}

		</Stack>
	)
}
export default Details