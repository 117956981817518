import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { pstyle, h6style, usageBoxStyle } from '../../../theme/customStyles'

const BugTrackerXUsage = () => {
	return (
		<Stack>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Getting Started</Typography>
				<Typography sx={pstyle}>Create an admin account at <a href="https://www.bugtrackerx.com/register" target="_blank" rel="noreferrer">https://www.bugtrackerx.com/register</a>. Upon registration, it will automatically log you in.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/register.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Live Demo</Typography>
				<Typography sx={pstyle}>If you just want to demo the site without creating an account, you can click on one of the four role demos at <a href="https://www.bugtrackerx.com/" target="_blank" rel="noreferrer">https://www.bugtrackerx.com/</a>.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/demo.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>The Dashboard</Typography>
				<Typography sx={pstyle}>The dashboard displays the total number of projects, tickets, and users, as well as their current statuses.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/dashboard.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Create Projects</Typography>
				<Typography sx={pstyle}>Admins have the capability to create projects.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/projects.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Create Users</Typography>
				<Typography sx={pstyle}>Both admins and project managers can create user accounts.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/users.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Create Tickets</Typography>
				<Typography sx={pstyle}>All roles are granted the permission to create tickets.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/tickets.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Tickets</Typography>
				<Typography sx={pstyle}>A ticket provides a brief description of the bug, a more detailed explanation, the bug's type, its status, priority level, assignee, submitter, due date, and any screenshot attachments.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/singleticket.jpg" alt="" />
				<Typography sx={pstyle}>Additionally, the system includes a feature for commenting and offers a comprehensive ticket history.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/comments.jpg" alt="" />
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/history.jpg" alt="" />
				<Typography sx={pstyle}>Users can search through tickets using any combination of name, status, and priority.</Typography>
				<img src="https://github.com/tris-n/bugtrackerx/raw/main/readme/images/search.jpg" alt="" />
			</Box>

		</Stack>
	)
}
export default BugTrackerXUsage