// React
import React from 'react'
import ReactDOM from 'react-dom/client'

// Custom MUI Theme
import CustomThemeProvider from './theme/CustomThemeProvider'

import App from './App'
import './index.css'



const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<CustomThemeProvider>
			<App />
		</CustomThemeProvider>
	</React.StrictMode>
)