// React
import { useState } from "react"

// Material UI
import { Container, Link, Stack, Typography } from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch'

// Framer Motion
import FadeInText from '../FramerComponents/FadeInText'

// React Modal
import Modal from 'react-modal'

// Custom styles
import { linkText } from '../../theme/customStyles'

// Comoponents
import Usage from "./Usage/Usage"

const iconStyle = {
	position: "relative",
	top: "5px",
	display: {
		mobile: "none",
		tablet: "initial",
	}
}



const LinkBox = ({info}) => {

	// Modal settings
	const [modalIsOpen, setIsOpen] = useState(false)
	
	const openModal = () => {
		setIsOpen(true)
	}
	
	const closeModal = () => {
		setIsOpen(false)
	}

	return (
		<>
			<FadeInText>
				<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={3} sx={{marginTop: "20px"}}>
					<Link sx={linkText} onClick={() => openModal()}>Quick Tour<LaunchIcon sx={iconStyle} /></Link>
					<Link sx={linkText} href={info.demoUrl} target="_blank" rel="noopener noreferrer">Live Demo<LaunchIcon sx={iconStyle} /></Link>
					{/* <Link sx={linkText} onClick={() => openModal()}>How To Use<LaunchIcon sx={iconStyle} /></Link> */}
					<Link sx={linkText} href={info.githubUrl} target="_blank" rel="noopener noreferrer">Github<LaunchIcon sx={iconStyle} /></Link>
				</Stack>
			</FadeInText>

			{/* Delete modal */}
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={{
					overlay: {
						zIndex: 2000000,
						backgroundColor: "rgba(0,0,0,0.4)"
					},
					content: {
						// backgroundColor: "#f2f4f8",
						backgroundColor: "#ffffff",
						borderRadius: "1rem",
						display: "flex",
						flexDirection: "column",
						// height: "160px",
						maxWidth: 880,
						margin: "auto"
						// height: "20rem"
					}
				}}
			>
				{/* <Container maxWidth="desktop" id="modal"> */}
					<Usage projectName={info.projectName} closeModal={closeModal} />
				{/* </Container> */}
			</Modal>
		</>
	)
}
export default LinkBox