// React
import { useState } from "react"

// Framer Motion
import { motion } from "framer-motion"

// Material UI
import { Box, Stack } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

// Framer Motion
import StaggerChildren from '../FramerComponents/StaggerChildren'

// Components
import TextInfo from "./TextInfo"
import LinkBox from "./LinkBox"
import Details from "./Details"
import TechStack from "./TechStack"
import ThumbNail from "./ThumbNail"

// Custom Styles
const boxStyle ={
	borderRadius: {
		mobile: "30px",
		tablet: "60px", 
		laptop: "60px", 
	},
	overflow: "hidden",
	position: "relative",
	top: "0px",
	boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
	"&:hover": {
		position: "relative",
		top: "-5px",
		boxShadow: "0px 10px 60px 20px rgba(0, 0, 0, 0.1)",
	},
	transition: "all 300ms ease-in",
}

const infoBoxStyle = {
	width: {
		mobile: "100%",
		tablet: "100%", 
		laptop: "50%", 
	},
	padding: {
		mobile: "20px 20px 20px 20px",
		tablet: "30px 40px 20px 40px", 
		laptop: "40px 60px 40px 60px", 
	},
}



// Project
const Project = ({info}) => {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
	const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))
	const isLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
	const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'))

	const [isHovered, setIsHovered] = useState(false)



	return (
		<motion.div 
			onHoverStart={() => setIsHovered(true)}
			onHoverEnd={() => setIsHovered(false)}
		>

			<Box sx={{...boxStyle, backgroundColor: info.backgroundColor}} id={info.projectName}>


				{/* LANDSCAPE */}
				{(isLaptop || isDesktop) && (
					<Stack direction="row">

						{/* Info Box */}
						<Stack direction="column" sx={infoBoxStyle}>
							<StaggerChildren>
								<TextInfo info={info} />
								<LinkBox info={info} />
							</StaggerChildren>
							<Details info={info} />
							{/* <TechStack info={info} /> */}
						</Stack>

						{/* Thumbnail Box */}
						<ThumbNail info={info} hoverState={isHovered} />		
						
					</Stack>
				)}


				{/* PORTRAIT */}
				{(isMobile || isTablet) && (
					<Stack direction="column">

						{/* Info Box */}
						<Stack direction="column" sx={infoBoxStyle}>
							<TextInfo info={info} />
							<LinkBox info={info} />

							{/* Thumbnail Box */}
							<ThumbNail info={info} hoverState={isHovered} />
							
							{/* Features and TechStack under image */}
							<Details info={info} />
							{/* <TechStack info={info} /> */}
						</Stack>
						
					</Stack>
				)}

			</Box>

		</motion.div>
	)
}
export default Project