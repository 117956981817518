// ABOUT ME - LONG
export const aboutMeVerbose = {
	intro: [
		// "Let's Work Together",
		"My journey into the world of fullstack development is a mosaic of creativity and technical curiosity. I began my career in design and advertising before leaving to pursue my dream of becoming a screenwriter. Over the years, this path endowed me with a unique lens to view the world—one that's deeply rooted in visual storytelling, design principles, and a keen understanding of audiences.",
		"Now, I'm channeling this diverse experience into the realm of web development, where design meets functionality. My goal? To bridge the gap between compelling narratives and technology, crafting web applications that aren't just functional, but also intuitive and engaging.",
		"I can help your team with:",
	],
	offerings: [
		{
			title: "Frontend Development",
			description: "Fluent in JavaScript, Typescript, HTML, and CSS, I've honed my skills to create interactive user interfaces with React and Next.js, enhancing their aesthetics with Material UI or Tailwind CSS, and custom CSS styles.",
		},
		{
			title: "Backend Development",
			description: "I'm adept at setting up server-side applications with Node.js and Next.js, employing MongoDB with Mongoose for database operations, and Express for routing and middleware. Additionally, I have experience with Firebase and Firestore for added functionality and real-time data handling.",
		},
		{
			title: "Problem Solving",
			description: "Screenwriting nurtured an analytical language-based mindset, a skill I now use to tackle coding challenges.",
		},
		{
			title: "User Empathy",
			description: "With a background in design, I appreciate the nuances of user experience, aiming to create interfaces that resonate with end users.",
		},
		{
			title: "Adaptability",
			description: "My varied career has made me adaptable, open to feedback, and always ready to learn and evolve.",
		},
		{
			title: "Collaborative Spirit",
			description: "I deeply value teamwork and collaboration. Whether it’s in brainstorming sessions, troubleshooting challenges, or project evaluations, I thrive in a cohesive, collective environment.",
		},
	],
	outro: "My diverse background, combined with my proficiency in modern web technologies, makes me a unique asset. I'm eager to kickstart my developer journey and collaborate with a team that values fresh perspectives. Interested in connecting?"
}



// ABOUT ME - SHORT
export const aboutMeConcise = {
	intro: [
		// "Let's Work Together",
		"From a foundation in design and advertising to the creative world of screenwriting, my journey into fullstack development has been anything but ordinary. This path has gifted me a unique lens — deeply rooted in visual storytelling, design principles, and audience understanding.",
		"Today, I merge these experiences in web development, striving to craft applications that are not only functional but also engaging and intuitive.",
		"I can help your team with:",
	],
	offerings: [
		{
			title: "Frontend Development",
			description: "Skilled in JavaScript, TypeScript, HTML, and CSS, I specialize in crafting interactive user interfaces using React and Next.js, complemented by Material UI, Tailwind CSS, and custom styling.",
		},
		{
			title: "Backend Mastery",
			description: "Proficient with server-side applications using Node.js and Next.js. I employ MongoDB with Mongoose and use Express for optimal routing. My toolkit also includes Firebase and Firestore for real-time capabilities.",
		},
		{
			title: "Analytical Problem Solving",
			description: "My screenwriting days nurtured a language-based analytical approach, which I now channel into coding challenges.",
		},
		{
			title: "User-Centric Design",
			description: "With design roots, I prioritize user experience, aiming to create resonant and intuitive interfaces.",
		},
		{
			title: "Adaptability",
			description: "A varied career has fostered my adaptability, ensuring I'm receptive to feedback and continuously evolving.",
		},
		{
			title: "Team Collaboration",
			description: "I cherish teamwork. From brainstorming to problem-solving, I excel in collaborative environments.",
		},
	],
	outro: "My multifaceted background, paired with expertise in modern web development, makes me a unique asset. Ready to embark on a collaborative journey?"
}