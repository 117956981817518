import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { pstyle, h6style, usageBoxStyle, usageCodeStyle } from '../../../theme/customStyles'

const HomeFinderUsage = () => {
	return (
		<Stack>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>The Homepage</Typography>
				<Typography sx={pstyle}>Displays the most recent listings in a carousel.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/explore.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Explore</Typography>
				<Typography sx={pstyle}>You can either view listings based on rent/sale categories or show the listings that have discount offers. Each listing displays its price, any discounts, the number of bedrooms, bathrooms, parking spots, and its current sale status.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/listings.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Getting Started</Typography>
				<Typography sx={pstyle}>Create an account at <a href="https://www.homefinder-app.com/sign-up" target="_blank" rel="noreferrer">https://www.homefinder-app.com/sign-up</a>. Upon registration, it will automatically log you in.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/register.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Live Demo</Typography>
				<Typography sx={pstyle}>If you just want to demo the site without creating an account, you can click on one of the four role demos at <a href="https://www.homefinder-app.com/sign-in" target="_blank" rel="noreferrer">https://www.homefinder-app.com/sign-in</a>.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/demo.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Your Profile</Typography>
				<Typography sx={pstyle}>This is where you can manage your listed properties, and view your current bids and successful purchases. It will also show you if you have any unread messages.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/profile.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Creating a Listing</Typography>
				<Typography sx={pstyle}>From your profile page, select <span style={usageCodeStyle}>Sell or rent your home</span>.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/profile.jpg" alt="" />
				<Typography sx={pstyle}>You can choose whether to <span style={usageCodeStyle}>sell</span> or <span style={usageCodeStyle}>rent</span>. List how many bedrooms and bathrooms it has, whether it has a parking spot, and if it's furnished. Set the price, offer a discount, and upload photos of your property.</Typography>
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Listings</Typography>
				<Typography sx={pstyle}>When you view an individual listing, you'll be able to see all of its information, browse a carousel of photos, and see where it is located on Google Maps.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/singlelisting.jpg" alt="" />
				<Typography sx={pstyle}>You can also immediately buy the property.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/sold.jpg" alt="" />
				<Typography sx={pstyle}>Or make a bid on the property.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/bid.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Messaging System</Typography>
				<Typography sx={pstyle}>There is a messaging system where people can contact and communicate with the owners.</Typography>
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/contact.jpg" alt="" />
				<img src="https://github.com/tris-n/homefinder/raw/main/readme/images/contact2.jpg" alt="" />
			</Box>

		</Stack>
	)
}
export default HomeFinderUsage