// Material UI
import { Typography } from "@mui/material"

// Framer Motion
import FadeInText from '../FramerComponents/FadeInText'

// Custom styles
import { projectName, regularText } from '../../theme/customStyles'



const TextInfo = ({info}) => {
	return (
		<>
			<FadeInText><Typography sx={projectName}>{info.projectName}</Typography></FadeInText>
			<FadeInText><Typography sx={regularText}>{info.projectDescription}</Typography></FadeInText>
		</>
	)
}
export default TextInfo