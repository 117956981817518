// Material UI
import { Stack, Typography } from "@mui/material"

// Custom styles
import { techStackText } from '../../theme/customStyles'


const TechStack = ({info}) => {
	return (
		<Stack direction="row" flexWrap="wrap" justifyContent="center" sx={{marginTop: "15px"}}>
			{info.techStack.map((tech, index) => (
				<Typography sx={{...techStackText, marginRight: "8px", marginBottom: "6px", backgroundColor: `${info.featureColor}`, padding: "3px 8px", borderRadius: "20px",}} key={index}>{tech}</Typography>
			))}
		</Stack>
	)
}
export default TechStack