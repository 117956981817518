// VARIABLES
const primaryColor = "#4F6A65"
const emailColor = "#bf389b"


// BIG TEXT - Welcome.jsx, 
export const bigText = {
	fontSize: {
		mobile: "50px",
		tablet: "70px",
		laptop: "88px",
	},
	lineHeight: {
		mobile: "1.1",
		tablet: "1.1",
		laptop: "1.25",
	},
	fontWeight: "bold",
	color: primaryColor,
}


// REGULAR TEXT - Welcome.jsx, 
export const regularText = {
	fontSize: {
		mobile: "18px",
		tablet: "18px",
		laptop: "18px",
	},
	fontWeight: "normal",
	color: primaryColor,
	"& a": {
		fontWeight: "bold",
		color: emailColor,
	},
	"& a:hover": {
		textDecoration: "none",
	}
}


// LINK TEXT - Welcome.jsx, 
export const linkText = {
	fontSize: "18px",
	fontWeight: "bold",
	color: primaryColor,
	textDecoration: {
		mobile: "underline",
		tablet: "none",
	},
	cursor: "pointer",
	"&:hover": {
		textDecoration: "underline"
	},
}


// PROJECT NAME TEXT
export const projectName = {
	fontSize: {
		mobile: "30px",
		tablet: "40px",
		laptop: "40px",
	},
	fontWeight: "bold",
	color: primaryColor,
}


// LETS WORK TEXT
export const letsWorkText = {
	fontSize: {
		mobile: "30px",
		tablet: "40px",
		laptop: "40px",
	},
	fontWeight: "bold",
	color: primaryColor,
	marginBottom: {
		mobile: "10px",
		tablet: "20px",
		laptop: "20px",
	},
}


// FEATURE TEXT
export const featureText = {
	fontSize: {
		mobile: "18px",
		tablet: "18px",
		laptop: "18px",
	},
	fontWeight: "normal",
	color: primaryColor,
}


// TECH STACK TEXT
export const techStackText = {
	fontSize: {
		mobile: "12px",
		tablet: "12px",
		laptop: "12px",
	},
	fontWeight: "normal",
	color: primaryColor,
  }


// TAB TEXT
export const tabText = {
	fontSize: {
		mobile: "18px",
		tablet: "18px",
		laptop: "18px",
	},
	fontWeight: "normal",
	color: primaryColor,
}
export const activeTabText = {
	fontSize: {
		mobile: "18px",
		tablet: "18px",
		laptop: "18px",
	},
	fontWeight: "bold",
	color: primaryColor,
}

// FEATURE STYLES
export const featureItemStyle = {
	padding: {
		mobile: "10px 15px 10px 15px",
		tablet: "15px 25px 15px 25px",
		laptop: "15px 25px 15px 25px",
	},
	borderRadius: "0px", 
}

export const featureItemFirstStyle = {
	padding: {
		mobile: "10px 15px 10px 15px",
		tablet: "15px 25px 15px 25px",
		laptop: "15px 25px 15px 25px",
	},
	borderRadius: {
		mobile: "20px 20px 0px 0px",
		tablet: "30px 30px 0px 0px", 
		laptop: "30px 30px 0px 0px", 
	}, 
}

export const featureItemLastStyle = {
	padding: {
		mobile: "10px 15px 10px 15px",
		tablet: "15px 25px 15px 25px",
		laptop: "15px 25px 15px 25px",
	},
	borderRadius: {
		mobile: "0px 0px 20px 20px",
		tablet: "0px 0px 30px 30px", 
		laptop: "0px 0px 30px 30px", 
	}, 
}



// USAGE TEXT
export const h5style = {
	fontSize: "24px",
	fontWeight: "600",
	lineHeight: "30px",
}

export const h6style = {
	fontSize: "20px",
	fontWeight: "600",
	lineHeight: "25px",
	marginBottom: "10px",
}

export const pstyle = {
	fontSize: "16px",
	// fontWeight: "600",
	lineHeight: "24px",
	marginBottom: "16px",
}

export const usageBoxStyle = {
	marginTop: "20px",
	"& img": {
		width: "100%",
		marginBottom: "10px",
		border: "1px solid rgba(0,0,0,0.2)",
	},
	"& a": {
		color: "blue",
	},
	"& a:visited": {
		color: "blue",
	},

}

export const usageCodeStyle = {
	backgroundColor: "rgba(0,0,0,0.1)", 
	padding: "4px", 
	borderRadius: "6px",
}