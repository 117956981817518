// React
import { useEffect, useState } from 'react'

// Framer Motion
import StaggerChildren from './components/FramerComponents/StaggerChildren'

// Material UI
import CssBaseline from '@mui/material/CssBaseline'

// Components
import Nav from './components/Nav'
import Welcome from './components/Welcome'
import Art from './components/Art'
import Skills from './components/Skills'
import Portfolio from './components/Portfolio'
import About from './components/About'



// App
const App = () => {

	const [activeSection, setActiveSection] = useState('home')

	// check which section is closest to the top
	const handleScroll = () => {
		const sections = ['home', 'BugTrackerX', 'HomeFinder', 'StudioPM', 'about']
		let minDistanceToTop = Infinity
		let closestSection = ''
	  
		for (let sectionId of sections) {
			const element = document.getElementById(sectionId)
			const rect = element.getBoundingClientRect()
		
			// Calculate the absolute distance from the top of the viewport
			const distanceToTop = Math.abs(rect.top)
		
			if (distanceToTop < minDistanceToTop) {
				minDistanceToTop = distanceToTop
				closestSection = sectionId
			}
		}
	  
		// console.log(`the closest section to the top is ${closestSection}`)
		setActiveSection(closestSection)
	}

	// run handlescroll whenever user scrolls
	useEffect(() => {
	
		window.addEventListener('scroll', handleScroll)
	
		// Clean up the listener on unmount
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}

	}, [])



	return (
		<>
			<CssBaseline />

			<Nav activeSection={activeSection} />

			<StaggerChildren>
				<Welcome />
				{/* <Art /> */}
				<Skills />
			</StaggerChildren>
			
			<Portfolio />

			<About />
		</>
	)
}

export default App