// Material UI
import { Box, Stack, Typography } from "@mui/material"

// Framer Motion
import StaggerChildren from "../FramerComponents/StaggerChildren"
import FadeInFeature from "../FramerComponents/FadeInFeature"

// Components
import TechStack from "./TechStack"

// Custom styles
import { featureText } from '../../theme/customStyles'

const featureBoxStyle = {
	// borderRadius: {
	// 	mobile: "20px 20px 0px 0px",
	// 	tablet: "30px 30px 0px 0px", 
	// 	laptop: "30px 30px 0px 0px", 
	// }, 
	// overflow: "hidden", 
	// marginTop: {
	// 	mobile: "20px",
	// 	tablet: "40px", 
	// 	laptop: "30px", 
	// },  
	// marginBottom: {
	// 	mobile: "0px",
	// 	tablet: "20px", 
	// 	laptop: "10px", 
	// }, 
}

const featureItemStyle = {
	padding: {
		mobile: "10px 15px 10px 15px",
		tablet: "15px 25px 15px 25px",
		laptop: "15px 25px 15px 25px",
	},
	borderRadius: "0px", 
}

const featureItemLastStyle = {
	padding: {
		mobile: "10px 15px 10px 15px",
		tablet: "15px 25px 15px 25px",
		laptop: "15px 25px 15px 25px",
	},
	borderRadius: {
		mobile: "0px 0px 20px 20px",
		tablet: "0px 0px 30px 30px", 
		laptop: "0px 0px 30px 30px", 
	}, 
}



const Features = ({info}) => {
	return (
		<Box sx={{marginBottom: { mobile: "0px", tablet: "20px",  laptop: "-15px",  }, }}>
			<StaggerChildren>
				
				{info.features.map((feature, index) => {
					const isLastFeature = index === (info.features.length - 1);
					const marginTopValue = index === 0 ? "1px" : "5px";
					const stackStyle = isLastFeature ? featureItemLastStyle : featureItemStyle;

					return (
						<FadeInFeature key={index}>
							<Stack
								direction="row"
								alignItems="center"
								sx={{
								...stackStyle,
								backgroundColor: info.featureColor,
								marginTop: marginTopValue,
								}}
							>
								<Typography sx={featureText}>
								<strong>{feature.title}:</strong> {feature.description}
								</Typography>
							</Stack>
						</FadeInFeature>
					);
				})}

				<FadeInFeature><TechStack info={info} /></FadeInFeature>

			</StaggerChildren>
		</Box>
	)
}
export default Features