// Material UI
import { useTheme } from '@mui/material/styles'
import { Box, Stack, useMediaQuery } from "@mui/material"

// Custom styles
const holderBoxStyle = {
	// border: "1px solid red",
	overflow: "hidden",
	cursor: "pointer",
	width: {
		mobile: "100%",
		tablet: "100%", 
		laptop: "50%", 
	},
	// minHeight: "580px",
	// backgroundColor: "#eb403420",
	paddingTop: {
		mobile: "15px",
		tablet: "30px",
		laptop: "50px",
	},
	paddingLeft: {
		mobile: "0px",
		tablet: "0px", 
		laptop: "0px", 
	},
}

const thumbnailStyle = {
	// backgroundImage: `url(${info.thumbnail})`,
	backgroundSize: {
		mobile: "cover",
		tablet: "cover",
		laptop: "100%",
	},
	// backgroundSize: "150%",
	backgroundRepeat: "no-repeat",
	backgroundPosition: {
		mobile: "top center",
		tablet: "top center",
		laptop: "top left",
		desktop: "top left",
	},
	width: {
		mobile: "100%",
		tablet: "100%", 
		laptop: "100%", 
	}, 
	// height: {tablet: "600px", mobile: "300px"}, 
	height: {
		mobile: "250px",
		tablet: "500px", 
		laptop: "100%", 
		desktop: "100%", 
	}, 
	// backgroundColor: "red", 
	borderRadius: {
		mobile: "20px 20px 20px 20px",
		tablet: "20px 20px 20px 20px", 
		laptop: "50px 0px 0px 0px", 
	},
	position: "relative",
	transition: "all 300ms ease-in",
}


const ThumbNail = ({info, hoverState}) => {

	const theme = useTheme()

	const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
	const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))
	const isLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
	const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'))

	const backgroundImage = (isMobile || isTablet) ? `url(${info.thumbnailMobile})` : `url(${info.thumbnail})`

	const handleClick = () => {
		window.open(info.demoUrl, '_blank')
	}

	return (
		<Stack direction="column" sx={holderBoxStyle} onClick={handleClick}>
			<Box sx={{...thumbnailStyle, backgroundImage: backgroundImage, backgroundColor: info.thumbnailBgColor, top: `${hoverState === true ? '10px' : '0px'}`}}></Box>
		</Stack>
	)
}
export default ThumbNail