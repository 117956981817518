// Material UI
import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { tabText, activeTabText } from '../../../theme/customStyles'

const tabBoxStyle = {
	// marginTop: {
	// 	mobile: "20px",
	// 	tablet: "40px", 
	// 	laptop: "30px", 
	// },  
	// borderRadius: {
	// 	mobile: "20px 20px 0px 0px",
	// 	tablet: "30px 30px 0px 0px", 
	// 	laptop: "30px 30px 0px 0px", 
	// }, 
	marginBottom: "5px", 
	cursor: "pointer",
}



// ChallengeTabs
const ChallengeTabs = ({info, selectedChallengeTab, setSelectedChallengeTab}) => {

	// Styles
	const tabStyle = {
		padding: {
			mobile: "0px 15px 0px 15px",
			tablet: "0px 25px 0px 25px",
			laptop: "0px 25px 0px 25px",
		},
		height: "70px",
		width: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	} 
	
	const activeTabStyle = {
		padding: {
			mobile: "0px 15px 0px 15px",
			tablet: "0px 25px 0px 25px",
			laptop: "0px 25px 0px 25px",
		},
		height: "70px",
		width: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderBottom: `10px solid ${info.tabColor}`, 
		paddingTop: "10px !important",
	}

	// Handle ChallengeTab Selection
	const handleChallengeTabClick = (tab) => {
		setSelectedChallengeTab(tab)
	}
	


	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{...tabBoxStyle, backgroundColor: info.featureColor}}>
					
			<Box sx={selectedChallengeTab === 0 ? activeTabStyle : tabStyle} onClick={() => handleChallengeTabClick(0)}>
				<Typography sx={selectedChallengeTab === 0 ? activeTabText : tabText} align="center">{info.challenges[0].title}</Typography>
			</Box>

			<Box sx={selectedChallengeTab === 1 ? activeTabStyle : tabStyle} onClick={() => handleChallengeTabClick(1)}>
				<Typography sx={selectedChallengeTab === 1 ? activeTabText : tabText} align="center">{info.challenges[1].title}</Typography>
			</Box>

		</Stack>
	)
}
export default ChallengeTabs