// Material UI
import { Box, Stack, Typography } from "@mui/material"

// Framer Motion
import StaggerChildren from "../../FramerComponents/StaggerChildren"
import FadeInFeature from "../../FramerComponents/FadeInFeature"

// Custom styles
import { featureText, featureItemStyle, featureItemFirstStyle, featureItemLastStyle } from '../../../theme/customStyles'



const Challenge = ({info, challenge}) => {
	return (
		<>

			{challenge.descriptions.map((description, index) => {
				const isLastFeature = index === (challenge.descriptions.length - 1);
				const moreThanOne = challenge.descriptions.length > 1
				const marginTopValue = index === 0 ? "1px" : "5px";
				const stackStyle = isLastFeature ? featureItemLastStyle : featureItemStyle;

				return (
					<FadeInFeature key={index}>
						<Stack
							direction="row"
							alignItems="center"
							sx={{
							...stackStyle,
							backgroundColor: info.featureColor,
							marginTop: marginTopValue,
							}}
						>
							<Typography sx={featureText}>
								{/* <strong>{challenge.title}:</strong> {challenge.description} */}
								{/* {index === 0 && <strong>Challenge{moreThanOne && "s"}:{challenge.descriptions.length} </strong>}{description.text} */}
								{index === 0 && <strong>Challenge{moreThanOne && "s"}: </strong>}{description.text}
							</Typography>
						</Stack>
					</FadeInFeature>
				);
			})}

		</>
	)
}
export default Challenge