import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { pstyle, h6style, usageBoxStyle } from '../../../theme/customStyles'

const ChatCapsuleUsage = () => {
	return (
		<Stack>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>The Homepage</Typography>
				<Typography sx={pstyle}>Upon visiting the homepage, you will not see any capsules until you log in. The interface is clean and straightforward, directing you to sign in to access full features.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/homepage.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Sign In</Typography>
				<Typography sx={pstyle}>To sign in, click the <strong>Sign In</strong> button located at the top right of the screen. You will be redirected to Google for authentication.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/signin.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Signed In</Typography>
				<Typography sx={pstyle}>After signing in, you'll be taken back to the homepage. You will now see options to <strong>Create Capsule</strong> and access your <strong>Profile</strong>, which will be represented by your Google Account avatar.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/signedin.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Create A Capsule</Typography>
				<Typography sx={pstyle}>To create a capsule:</Typography>
				<Typography sx={pstyle}>1. Click on Create Capsule.</Typography>
				<Typography sx={pstyle}>2. You will be prompted to fill out four required fields:</Typography>
				<ul>
					<li>URL: Paste the URL of your ChatGPT conversation.</li>
					<li>Title: Enter the title used by ChatGPT to save your conversation.</li>
					<li>Summary: Enter a summary of the conversation.</li>
					<li>Tags: Enter relevant tags for the conversation (separate each tag with a comma).</li>
				</ul>
				<Typography sx={pstyle}><strong>Tip:</strong> Use the following prompt in ChatGPT to generate a summary and tags: "Create a simple 100-word summary and basic tags for this conversation."</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/createcapsule.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Your Capsule</Typography>
				<Typography sx={pstyle}>Once created, your capsule will appear on the homepage. Each capsule offers quick options to:</Typography>
				<ul>
					<li>Open the ChatGPT conversation in a new window by clicking the link icon.</li>
					<li>Search for other conversations using similar tags by clicking on a tag.</li>
					<li>Edit or delete your capsule.</li>
				</ul>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/newcapsule.jpg" alt="" />
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/multiplecapsules.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Searching Capsules</Typography>
				<Typography sx={pstyle}>Search for specific conversations using the search box or by clicking on tags. The system searches through titles, summaries, and tags for relevant results.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/searchresults.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Editing Capsules</Typography>
				<Typography sx={pstyle}>Modify your existing capsules at any time by selecting the edit option on each capsule card.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/editcapsule.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Your Profile Page</Typography>
				<Typography sx={pstyle}>Visit your profile page for a streamlined overview of all your capsules, making it easier to manage and reference your saved conversations.</Typography>
				<img src="https://github.com/tris-n/chatcapsule/raw/main/readme/images/profilepage.jpg" alt="" />
			</Box>

		</Stack>
	)
}
export default ChatCapsuleUsage