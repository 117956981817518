// Changelog
// 30 Apr 2024: Added ChatCapsule to site.

// Images
import BugTrackerXThumbnail from '../images/screenshots/bugtrackerx-vertical.png'
import BugTrackerXThumbnailMobile from '../images/screenshots/bugtrackerx-mobile.png'

import HomeFinderThumbnail from '../images/screenshots/homefinder-vertical.png'
import HomeFinderThumbnailMobile from '../images/screenshots/homefinder-mobile.png'

import StudioPMThumbnail from '../images/screenshots/studiopm-vertical.png'
import StudioPMThumbnailMobile from '../images/screenshots/studiopm-mobile.png'

import ChatCapsuleThumbnail from '../images/screenshots/chatcapsule-vertical.png'
import ChatCapsuleThumbnailMobile from '../images/screenshots/chatcapsule-mobile.png'

// Project Information
export const projects = [

	{
		projectName: "BugTrackerX",
		projectDescription: "A comprehensive tool designed for businesses to efficiently track and manage software bugs. Built with the MERN stack.",
		backgroundColor: "#F4E2D9", // peach
		featureColor: "#EED3C7",
		tabColor: "#F5B69D",
		selectedTab: "#F09B79",
		// githubUrl: "https://github.com/tris-n/portfolio-bugtracker-tr",
		githubUrl: "https://github.com/tris-n/bugtrackerx",
		// demoUrl: "https://bugtrackerx.herokuapp.com/",
		demoUrl: "https://www.bugtrackerx.com/",
		thumbnail: BugTrackerXThumbnail,
		thumbnailMobile: BugTrackerXThumbnailMobile,
		thumbnailBgColor: "#F6F4F1",

		features: [
			{
				title: "Fullstack Integration",
				description: "Front-to-back CRUD operations for smooth functionality.",
			},
			{
				title: "Dynamic Project & User Management",
				description: "Create projects, manage users, and assign roles with ease.",
			},
			{
				title: "Advanced Ticketing System",
				description: "Allows users to report, monitor, and track ticket histories.",
			},	
			{
				title: "Secure & Resilient Database",
				description: "Role-based access control with automated data backup and rollback.",
			},
			{
				title: "Collaborative Tools",
				description: "Advanced search, real-time commenting, and attachment support for enhanced teamwork.",
			},
		],

		role: [
			{
				title: "",
				description: "I was responsible for the entire development lifecycle. This included conceptualizing the app, designing the UI/UX, developing the front-end with React, setting up the back-end with Node.js and Express.js, and managing data with MongoDB. I also ensured the application was fully responsive and user-friendly.",
			}
		],

		challenges: [
			{
				title: "Tracking Changes",
				details: [
					{
						challenge: "Implementing Ticket Change Tracking",
						descriptions: [
							{
								text: "Developing a system in BugTrackerX to track ticket changes, handling creations, updates, and deletions."
							},
							{
								text: "Overcoming the difficulty of comparing fields without initial values and integrating tracking with database operations."
							},
						],
					},
					{
						solution: "Creating a History Collection and Comparison Mechanism",
						descriptions: [
							{
								text: "Introduced a 'history' collection in the database for tracking changes."
							},
							{
								text: "Implemented a history function that triggers on ticket updates, involving comparison of the original and updated documents."
							},
							{
								text: "Designed a complex mechanism to identify and record changes, linking them to specific tickets."
							},
						],
					},
					{
						learning: "Advanced Database Management and Data Comparison",
						descriptions: [
							{
								text: "Gained expertise in database management, particularly in managing a history collection."
							},
							{
								text: "Learned complex data comparison techniques, useful for scenarios with missing initial values."
							},
							{
								text: "Enhanced understanding of integrating front-end and back-end functions for a seamless display of data histories."
							},
						],
					},
				],
			},

			{
				title: "Optimising Page Load",
				details: [
					{
						challenge: "Optimizing Page Load Performance",
						descriptions: [
							{
								text: "Faced slow page loading times in BugTrackerX, exacerbated by its complexity and high number of server requests."
							},
							{
								text: "The main goal was to enhance performance without sacrificing functionality."
							},
						],
					},
					{
						solution: "Streamlining Server Requests and Enhancing Data Retrieval",
						descriptions: [
							{
								text: "Implemented optimizations including:",
								steps: [
									{
										step: "Reducing Server Calls",
										description: "Minimized server requests, especially on high-interaction pages.",
									},
									{
										step: "Dedicated Dashboard Controller",
										description: "Established a controller for efficient dashboard request handling.",
									},
									{
										step: "Utilization of Promises in JavaScript",
										description: "Managed asynchronous operations for smoother data retrieval.",
									},
									{
										step: "Aggregation in Database Queries",
										description: "Used aggregation to fetch consolidated data in fewer calls.",
									},
									{
										step: "Independent Data and UI Loading",
										description: "Allowed static page elements to load first, with background data-fetching operations, improving perceived performance.",
									},
								],
							},
						],
					},
					{
						learning: "Performance Optimization and Efficient Data Handling",
						descriptions: [
							{
								text: "Gained insights into web application performance optimization, such as reducing server requests and managing asynchronous operations."
							},
							{
								text: "Enhanced skills in writing efficient, scalable code for data-intensive applications."
							},
							{
								text: "Developed an understanding of the interplay between front-end and back-end performance, and the importance of balancing visual feedback with data processing."
							},
						],
					},
				],
			},
		],

		techStack: [
			// Frontend
			"Javascript",  
			"React", 
			"Material-UI", 
			"Redux",
			"Firebase",
			"Firestore",
			"Framer Motion",
			"ApexCharts",
			"Dayjs",
			// Backend
			"NodeJS",
			"MongoDB", 
			"Mongoose", 
			"Express", 
			"bcryptjs",
		],
	},

	{
		projectName: "HomeFinder",
		projectDescription: "A dynamic platform for buying, selling, and renting properties. Built with React and Firebase.",
		// projectDescription: "HomeFinder is a web application that facilitates buying, selling, and renting properties. It's built with React for the front-end and Firebase for the back-end services.",
		backgroundColor: "#E9F0FD", // purple
		featureColor: "#D7E1F1",
		// tabColor: "#AFCBFF",
		// selectedTab: "#5A78A7",
		tabColor: "#99B9F4",
		selectedTab: "#7494C6",
		// githubUrl: "https://github.com/tris-n/portfolio-home-mp",
		githubUrl: "https://github.com/tris-n/homefinder",
		// demoUrl: "https://homefinder-trisn-145fbedc260b.herokuapp.com/",
		demoUrl: "https://www.homefinder-app.com/",
		thumbnail: HomeFinderThumbnail,
		thumbnailMobile: HomeFinderThumbnailMobile,
		thumbnailBgColor: "#F2F4F8",

		features: [
			{
				title: "Fullstack Integration",
				description: "Front-to-back CRUD operations for smooth functionality. Responsive design ensures optimal viewing and interaction across a range of devices.",
			},
			{
				title: "User Management",
				description: "Secure user authentication and account management using Firebase and Google OAuth.",
			},
			{
				title: "Comprehensive Property Listings",
				description: "Users can post properties for sale or rent, complete with pricing and discount options, photo galleries, and Google Maps integration.",
			},
			{
				title: "Dynamic Transactions",
				description: "Seamless buying or renting experience also includes a bidding system that allows prospective buyers to bid on properties.",
			},
			{
				title: "Secure & Resilient Database",
				description: "Role-based access control with automated data backup and rollback.",
			},
			{
				title: "Messaging and Notifications",
				description: "In-app messaging system lets users converse directly. Users are also alerted when a property status changes, ensuring they're always in the loop.",
			},
		],

		role: [
			{
				title: "",
				description: "As the sole developer, I designed and implemented the entire application. This involved creating an intuitive UI/UX, developing interactive front-end features with React, and integrating Firebase for backend functionalities like database management and authentication. I focused on creating a seamless user experience for property transactions.",
			}
		],

		challenges: [
			{
				title: "Communication System",
				details: [
					{
						challenge: "Streamlining Communication for Transactions and Inquiries",
						descriptions: [
							{
								text: "Addressed the need for an efficient communication system in HomeFinder, allowing buyers to contact sellers and receive notifications for bids and purchases seamlessly within the app."
							},
						],
					},
					{
						solution: "Implementing a Robust Messaging System within User Documents",
						descriptions: [
							{
								text: "Developed a messaging system, incorporating `messages` and `newMessages` fields in user documents for easy tracking."
							},
							{
								text: "Implemented transaction messaging, with automatic message generation and notifications for transaction activities."
							},
							{
								text: "Enabled direct contact between users through a UI contact button, facilitating message exchange."
							},
							{
								text: "Designed message document creation and retrieval within user profiles, ensuring users could view and manage their messages easily."
							},
						],
					},
					{
						learning: "Effective User Communication and Database Management",
						descriptions: [
							{
								text: "Learned to create effective communication channels in a web application, specifically for a real estate platform."
							},
							{
								text: "Improved database schema design skills, integrating communication functionalities within user documents."
							},
							{
								text: "Gained insights into UI/UX design, focusing on intuitive communication features, enhancing user engagement and satisfaction."
							},
						],
					},
				],
			},

			{
				title: "Cloud Functions",
				details: [
					{
						challenge: "Automating Database Functions in Firebase",
						descriptions: [
							{
								text: "Faced challenges in automating backend-like functions in HomeFinder's Firebase-based serverless framework, especially for database backups and rollbacks."
							},
						],
					},
					{
						solution: "Leveraging Firebase Cloud Functions for Automation",
						descriptions: [
							{
								text: "Utilized Firebase Cloud Functions to manage automated tasks:",
								steps: [
									{
										step: "Backup Cloud Function",
										description: "Developed a function to systematically save all documents into a consolidated backup.",
									},
									{
										step: "Scheduled Rollback Process",
										description: "Implemented a daily Pub/Sub schedule to trigger a rollback function for data integrity.",
									},
									{
										step: "Rollback Execution",
										description: "This function reset the database to a clean state by deleting current listings and users collections.",
									},
									{
										step: "Database Repopulation",
										description: "Post-deletion, the function repopulated the database using data from the backup document.",
									},
								],
							},
						],
					},
					{
						learning: "Serverless Architecture and Scheduled Data Management",
						descriptions: [
							{
								text: "Gained insights into serverless architectures, specifically using cloud functions in Firebase for backend operations."
							},
							{
								text: "Enhanced understanding of automated data management, including backup strategies and data restoration processes."
							},
							{
								text: "Deepened knowledge of Firebase's capabilities in data integrity and safety, highlighting the importance of regular backups and robust rollback mechanisms."
							},
						],
					},
				],
			},
		],

		techStack: [
			// Frontend
			"Javascript",  
			"React", 
			"Firebase", 
			"Firestore",
			"Framer Motion",
			"Leaflet",
			"Swiper",
			"Toastify",
			// Backend
			"NodeJS", 
			"MongoDB", 
			"Mongoose",
			"Express", 
			"Firebase-Admin",
			"bcryptjs",
			// APIs
			"GoogleMaps API",
			"Google OAuth",
		],
	},

	{
		projectName: "StudioPM",
		projectDescription: "A comprehensive film production management tool. Built with the MERN stack.",
		backgroundColor: "#E0EBE6", // green
		featureColor: "#CFDDD8",
		// tabColor: "#A3C9A8",
		// selectedTab: "#4A6658",
		tabColor: "#9FBFA3",
		selectedTab: "#6A9680",
		// githubUrl: "https://github.com/tris-n/portfolio-studio-pm",
		githubUrl: "https://github.com/tris-n/studiopm",
		// demoUrl: "https://studiopm.herokuapp.com/",
		demoUrl: "https://www.mystudiopm.com/",
		thumbnail: StudioPMThumbnail,
		thumbnailMobile: StudioPMThumbnailMobile,
		thumbnailBgColor: "#5b7490",

		features: [
			{
				title: "Fullstack Development",
				description: "Built a responsive CRUD application ensuring a streamlined user experience across devices.",
			},
			{
				title: "Secure User Management",
				description: "Integrated role-based security and permissions to accommodate different user roles within a film studio.",
			},
			{
				title: "Database Resilience",
				description: "Designed a system with automated rollback and backup to ensure data consistency and security.",
			},	
			{
				title: "Dynamic Project Management",
				description: "Create projects, define deadlines, set budgets, assign roles, and manage critical attachments.",
			},
			{
				title: "Extensive Data Integration",
				description: "Prefill projects with random movie data or fetch specific movie details using TheMovieDatabase API.",
			},
			{
				title: "Document Management",
				description: "Upload and manage key assets like movie posters and scripts.",
			},
			// {
			// 	title: "Advanced Search Capabilities",
			// 	description: "Designed intuitive search functionalities to locate users and projects, allowing sorting by status, category, and other parameters.",
			// },
			// {
			// 	title: "Real-time Project Monitoring",
			// 	description: "Introduced a dynamic dashboard to track project statuses and manage deliverables effectively.",
			// },
		],

		role: [
			{
				title: "",
				description: "I was responsible for the entire development lifecycle. This included conceptualizing the app, designing the UI/UX, developing the front-end with React, setting up the back-end with Node.js and Express.js, and managing data with MongoDB. I also ensured the application was fully responsive and user-friendly.",
			}
		],

		challenges: [
			{
				title: "Search Functionality",
				details: [
					{
						challenge: "Optimizing Search Functionality with The Movie Database API",
						descriptions: [
							{
								text: "Encountered issues in StudioPM with search functionality interfacing with The Movie Database API, leading to inaccurate results and excessive server requests."
							},
						],
					},
					{
						solution: "Implementing Debounce on Search Input",
						descriptions: [
							{
								text: "Implemented a debounce technique on the search input, involving:",
								steps: [
									{
										step: "Adding a 500ms Delay",
										description: "Reduced request frequency, allowing more complete user input.",
									},
									{
										step: "State Management for Search Terms",
										description: "Ensured search requests were sent after a pause in typing.",
									},
									{
										step: "Enhanced Search Accuracy and Reduced Server Traffic",
										description: "Resulted in more accurate results and less server load.",
									},
								],
							},
						],
					},
					{
						learning: "Efficient API Interaction and User Experience Enhancement",
						descriptions: [
							{
								text: "Learned efficient API interaction and user experience optimization, including:",
								steps: [
									{
										step: "Optimizing API Calls",
										description: "Gained insights into effective handling of external API responses.",
									},
									{
										step: "Improving Search Experience",
										description: "Enhanced user search experience by adapting to user input patterns.",
									},
									{
										step: "Performance Optimization",
										description: "Reduced server load and network traffic.",
									},
									{
										step: "Front-end Responsiveness",
										description: "Improved skills in creating responsive user interfaces.",
									},
								],
							},
						],
					},
				],
			},

			{
				title: "Access Control",
				details: [
					{
						challenge: "Implementing Role-Based Access Control",
						descriptions: [
							{
								text: "Managed access control for various user types in StudioPM, aiming to deliver specific functionalities based on user roles, balancing security and user experience."
							},
						],
					},
					{
						solution: "Integrating JWTs and Role-Based Logic in Backend and Frontend",
						descriptions: [
							{
								text: "Implemented a role-based access control system:",
								steps: [
									{
										step: "Backend with JWTs",
										description: "Utilized JSON Web Tokens for user role encoding and developed authentication middleware for role verification.",
									},
									{
										step: "Frontend with React-Router-Dom",
										description: "Managed route access and redirection for unauthorized users, ensuring role-appropriate content display.",
									},
									{
										step: "Component-Level Access Control",
										description: "Tailored UI components to user roles for dynamic rendering.",
									},
								],
							},
						],
					},
					{
						learning: "Advanced Access Control and Security Implementation",
						descriptions: [
							{
								text: "Gained expertise in:",
								steps: [
									{
										step: "Secure User Authentication",
										description: "Effective use of JWTs for authentication and role verification.",
									},
									{
										step: "Frontend-Backend Security Coordination",
										description: "Understanding the synergy between frontend and backend security.",
									},
									{
										step: "Dynamic UI Rendering Based on User Roles",
										description: "Experience in user-role-driven UI component rendering.",
									},
									{
										step: "Error Handling and User Feedback",
										description: "Importance of proper error handling in unauthorized access scenarios.",
									},
								],
							},
						],
					},
				],
			},
		],

		techStack: [
			// Frontend
			"Javascript",  
			"React", 
			"Material-UI", 
			"Redux",
			"Firebase",
			"Firestore",
			"Framer Motion",
			"Lodash",
			"Dayjs",
			// Backend
			"NodeJS", 
			"MongoDB", 
			"Mongoose", 
			"Express", 
			"bcryptjs",
			// APIS
			"themoviedatabase API",
		],
	},

	{
		projectName: "ChatCapsule",
		projectDescription: "A platform for saving ChatGPT conversations for future reference. Built with TypeScript and Next.js.",
		backgroundColor: "#F5F1E6", // yellow
		featureColor: "#EEE7D1",
		tabColor: "#E0D5AB",
		selectedTab: "#DCCC88",
		// backgroundColor: "#F4E2D9", // peach
		// featureColor: "#EED3C7",
		// tabColor: "#F5B69D",
		// selectedTab: "#F09B79",
		githubUrl: "https://github.com/tris-n/chatcapsule",
		demoUrl: "https://portfolio-chatcapsule-nextjs14.vercel.app/",
		thumbnail: ChatCapsuleThumbnail,
		thumbnailMobile: ChatCapsuleThumbnailMobile,
		thumbnailBgColor: "#F6F4F1",

		features: [
			{
				title: "Seamless Integration",
				description: "Utilizes Next.js for handling both server-side operations and frontend rendering, ensuring a fluid user experience.",
			},
			{
				title: "Advanced Document Management",
				description: "Allows users to create, tag, summarize, and store chat capsules for easy access and searchability.",
			},
			{
				title: "Secure Authentication",
				description: "Incorporates Google OAuth via Next-Auth for secure and easy user login.",
			},	
			{
				title: "Responsive Design",
				description: "Offers a tailored user interface for both desktop and mobile platforms, adapting layout dynamically.",
			},
			{
				title: "Database Functionality",
				description: "Employs MongoDB via Mongoose for robust data management, ensuring efficient operations and scalability.",
			},
		],

		role: [
			{
				title: "",
				description: "As the sole developer of ChatCapsule, I managed the entire development lifecycle, from concept to deployment. I designed the UI/UX, implemented full-stack functionality using TypeScript and Next.js 14, integrated Google OAuth for authentication, and managed data with MongoDB, ensuring a seamless and responsive user experience.",
			}
		],

		challenges: [
			{
				title: "Deployment Variables",
				details: [
					{
						challenge: "Deployment and Variables",
						descriptions: [
							{
								text: "Faced deployment issues with ChatCapsule on Vercel due to Next-Auth errors, exacerbated by ambiguous error messages and inconclusive Stack Overflow posts."
							},
							{
								text: "Even extensive logging did not immediately reveal the issue."
							},
						],
					},
					{
						solution: "Compare to working example.",
						descriptions: [
							{
								text: "By comparing my project's configuration with a working example from GitHub, I identified the omission of the `NEXTAUTH_SECRET` environment variable, crucial for deployment."
							},
							{
								text: "Adding this variable resolved the deployment errors."
							},
							// {
							// 	text: "Designed a complex mechanism to identify and record changes, linking them to specific tickets."
							// },
						],
					},
					{
						learning: "Deploy early to catch errors sooner.",
						descriptions: [
							{
								text: "This experience underscored the importance of early and frequent deployments to catch such issues sooner. "
							},
							{
								text: "It also highlighted the effectiveness of comparing with working codebases to diagnose problems."
							},
							// {
							// 	text: "Enhanced understanding of integrating front-end and back-end functions for a seamless display of data histories."
							// },
						],
					},
				],
			},

			{
				title: "Database Errors",
				details: [
					{
						challenge: "Deployment and Variables",
						descriptions: [
							{
								text: "Users experienced JSON errors if they returned to the site after it had been idle for some time."
							},
							{
								text: "This issue was difficult to diagnose because it required waiting for the error to manifest after a period of inactivity."
							},
						],
					},
					{
						solution: "Log where the issue is coming from.",
						descriptions: [
							{
								text: "After thorough testing and simplifying my database connection logic, I realized that the MongoDB connection states were inaccurately reported as valid when they were stale."
							},
							{
								text: "The final solution was to eliminate the check for an existing database connection and establish a new connection as needed, ensuring reliability regardless of user activity levels."
							},
						],
					},
					{
						learning: "Learnt about database connections.",
						descriptions: [
							{
								text: "This challenge taught me more about database connection management and the necessity of robust error handling."
							},
							{
								text: "It also led to a more streamlined approach to database connections, enhancing the site's stability and performance."
							},
						],
					},
				],
			},
		],

		techStack: [
			// Core Technologies
			"TypeScript", "Next.js", "Tailwind CSS",
			// Database
			"MongoDB", "Mongoose", 
			// Authentication
			"Google Oauth", "Next-Auth",
		],
	},
	
]