import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { pstyle, h6style, usageBoxStyle, usageCodeStyle } from '../../../theme/customStyles'

const StudioPMUsage = () => {
	return (
		<Stack>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Getting Started</Typography>
				<Typography sx={pstyle}>Create a <span style={usageCodeStyle}>Studio Head</span> account at <a href="https://www.mystudiopm.com/register" target="_blank" rel="noreferrer">https://www.mystudiopm.com/register</a>. Upon registration, you will be automatically logged in.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/register.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Live Demo</Typography>
				<Typography sx={pstyle}>If you just want to demo the site without creating an account, you can click on one of the four role demos at <a href="https://www.mystudiopm.com/" target="_blank" rel="noreferrer">https://www.mystudiopm.com/</a>.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/demo.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>The Dashboard</Typography>
				<Typography sx={pstyle}>The dashboard displays the most recent projects and provides an overview of the studio's budget.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/dashboard.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Projects</Typography>
				<Typography sx={pstyle}>Admins have the capability to create projects.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/projects.jpg" alt="" />
				<Typography sx={pstyle}>Users can search for projects by name and status.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/search.jpg" alt="" />
				<Typography sx={pstyle}>Click on the <span style={usageCodeStyle}>Crew</span> tab to see an overview of who is assigned to each project.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/searchCrew.jpg" alt="" />
				<Typography sx={pstyle}>The <span style={usageCodeStyle}>Deliverables</span> tab provides an overview of the items supplied to each project.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/searchDeliverables.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Creating a Project</Typography>
				<Typography sx={pstyle}>When creating a project, you can set its release date, budget, assign crew, upload a script, a poster image, and provide a YouTube trailer.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/createProject.jpg" alt="" />
				
				<Typography sx={pstyle}>Additionally, you can choose to pre-fill the project with a randomly selected movie from TheMovieDB's <span style={usageCodeStyle}>trending</span> list or search for a specific movie in their database.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/prefill.jpg" alt="" />
			</Box>

			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Project Pages</Typography>
				<Typography sx={pstyle}>On the individual project pages, you can watch the trailers, read the scripts, get a status overview, and see which crew members have been assigned and whether they have provided their appropriate deliverables.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/singleProject.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Crew</Typography>
				<Typography sx={pstyle}>Both studio heads and producers can create crew accounts.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/crew.jpg" alt="" />
			</Box>
			
			<Box sx={usageBoxStyle} >
				<Typography sx={h6style}>Crew Pages</Typography>
				<Typography sx={pstyle}>Here, you can get an overview of which projects crew members are assigned to and which deliverables they have provided.</Typography>
				<img src="https://github.com/tris-n/studiopm/raw/main/readme/images/singleCrew.jpg" alt="" />
			</Box>

		</Stack>
	)
}
export default StudioPMUsage