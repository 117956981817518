// Framer Motion
import StaggerChildren from './FramerComponents/StaggerChildren'
import FadeInAndDownText from './FramerComponents/FadeInAndDownText'

// Material UI
import {Box, Container, Link, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// Images
import JavaScriptLogo from '../images/logos/javascriptnew.svg'
import TypeScriptLogo from '../images/logos/typescriptnew.svg'
import MongoDBLogo from '../images/logos/mongodb.svg'
import ExpressLogo from '../images/logos/express.svg'
import ReactLogo from '../images/logos/react.svg'
import NextLogo from '../images/logos/nextjs.svg'
import NodeLogo from '../images/logos/node.svg'
import FirebaseLogo from '../images/logos/firebase.svg'

// Custom styles
import { bigText, regularText, linkText } from '../theme/customStyles'

const containerStyle = {
	marginTop: {
		mobile: "60px",
		tablet: "60px",
		laptop: "90px",
		desktop: "120px",
	}, 
	marginBottom: {
		mobile: "45px",
		tablet: "60px",
		laptop: "60px",
	}, 
	// marginBottom: "60px",
}

const skillStackStyle = {
	backgroundColor: "#FFFFFF90",
	backdropFilter: "blur(10px)",
	paddingLeft: "10px",
	paddingRight: "10px",
	marginLeft: {
		mobile: "0px",
		tablet: "0px",
		laptop: "15px",
	},
	borderRadius: "26px",
}

const imageStyle = {
	display: "flex",
	height: {
		mobile: "52px",
		tablet: "52px",
		laptop: "52px",
	},
	padding: {
		mobile: "0px 10px 0px 10px",
		tablet: "0px 10px 0px 10px",
		laptop: "0px 15px 0px 15px",
	},
	// border: "1px solid red",
}



// Data
const mySkillsGrouped = [
	[
		{
			name: "Javascript",
			image: JavaScriptLogo
		},
		{
			name: "React",
			image: ReactLogo
		},
	],
	[
		{
			name: "TypeScript",
			image: TypeScriptLogo
		},
		{
			name: "NextJS",
			image: NextLogo
		},
	],
	[
		{
			name: "MongoDB",
			image: MongoDBLogo
		},
		{
			name: "Express",
			image: ExpressLogo
		},
	],
	[
		{
			name: "Node",
			image: NodeLogo
		},
		{
			name: "Firebase",
			image: FirebaseLogo
		},
	],
]



const Skills = () => {

	return (
		<Container maxWidth="desktop" sx={containerStyle}>
			<FadeInAndDownText>
				<Stack direction={{laptop: "row", tablet: "column", mobile: "column"}} alignItems="center" justifyContent="center" spacing={{laptop: 1, mobile: 1}}>
						<Typography sx={regularText}>My stack</Typography>

						<Stack sx={skillStackStyle} direction={{tablet: "row", mobile: "row"}} alignItems="center" justifyContent="center" flexWrap="wrap" >

							{mySkillsGrouped.map((group, index) => (
								<Stack direction={{desktop: "row", tablet: "column", mobile: "row"}} alignItems="center" justifyContent="space-evenly" key={index} spacing={{laptop: 0, tablet: 0, mobile: 4}}>
									{group.map((skill, index) => (
										<Box sx={imageStyle} key={index}>
											<img src={skill.image} alt={skill.name} key={skill.name} height="100%" />
										</Box>
									))}
								</Stack>
							))}

						</Stack>
				</Stack>
			</FadeInAndDownText>
		</Container>
	)
}
export default Skills