// Components
import TitleBar from "./TitleBar"
import BugTrackerXUsage from "./BugTrackerXUsage"
import HomeFinderUsage from "./HomeFinderUsage"
import StudioPMUsage from "./StudioPMUsage"
import ChatCapsuleUsage from "./ChatCapsuleUsage"



const Usage = ({projectName, closeModal}) => {
	return (
		<div>
			{/* Title Bar with Close Button */}
			<TitleBar projectName={projectName} closeModal={closeModal} />

			{/* Content - just do this inline */}
			{projectName === "BugTrackerX" && <BugTrackerXUsage />}
			{projectName === "HomeFinder" && <HomeFinderUsage />}
			{projectName === "StudioPM" && <StudioPMUsage />}
			{projectName === "ChatCapsule" && <ChatCapsuleUsage />}
		</div>
	)
}
export default Usage