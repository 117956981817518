// Material UI
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'



const customTheme = createTheme({
	breakpoints: {
		values: {
			// mobile: 0,
			// tablet: 900, // was 900. if you select this, anything 426px and above will be rendered
			// laptop: 1200,
			// desktop: 1500,
			mobile: 0,
			tablet: 426,
			laptop: 901,
			desktop: 1200,
		},
	},
	palette: {
		background: {
			default: "#F6F4F1",
		},
		primary: {
			main: '#3f51b5',
		},
		secondary: {
			main: '#f50057',
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
	},
})



const BreakpointGuide = () => {

	const theme = useTheme()

	const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
	const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))
	const isLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
	const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'))

	return (
		<Box display="flex" justifyContent="flex-end" sx={{backgroundColor: "red", paddingRight: "10px", zIndex: "25000", position: "absolute", right: 0, top: 0}}>
			Guides on: 
			{isMobile === true && " Mobile"}
			{isTablet === true && " Tablet"}
			{isLaptop === true && " Laptop"}
			{isDesktop === true && " Desktop"}
		</Box>
	)
}



const CustomThemeProvider = ({children}) => {

	let guides = true
	
	return (
		<ThemeProvider theme={customTheme}>
			{/* {guides && <BreakpointGuide /> } */}
			{children}
		</ThemeProvider>
	)
}
export default CustomThemeProvider