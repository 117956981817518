// Material UI
import {Box, Container, Link, Stack, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'

// Framer Motion
import StaggerChildren from './FramerComponents/StaggerChildren'
import FadeInAndDownText from './FramerComponents/FadeInAndDownText'

// Images
import Stars from '../images/stars.png'

// Custom styles
import { bigText, regularText, linkText } from '../theme/customStyles'

const stackStyle = {
	paddingTop: {
		mobile: "30px",
		tablet: "45px",
		laptop: "60px",
		desktop: "90px",
	},
	position: "relative",
	zIndex: 1,
}

const subCopyStyle = {
	marginTop: {
		mobile: "20px",
		tablet: "10px",
		laptop: "10px",
	},
	marginBottom: {
		mobile: "10px",
		tablet: "5px",
		laptop: "10px",
	},
	padding: {
		mobile: "0px",
		tablet: "0px 130px 0px 130px",
		laptop: "0px",
	}
}

const starStyle = {
	height: {
		mobile: "430px",
		tablet: "400px", 
		laptop: "400px", 
	},  
	width: "100%", 
	backgroundImage: `url(${Stars})`,
	backgroundSize: "cover",
	backgroundPosition: "center",
	position: "absolute", 
	opacity: 0.5,
	zIndex: -1,
	animation: 'fadeInOut 5s infinite',
}

const iconStyle = {
	position: "relative",
	top: "5px",
}



const Welcome = () => {

	return (
		<Container maxWidth="desktop">
			<Stack direction="column" alignItems="center" justifyContent="center" sx={stackStyle}>

				{/* <StaggerChildren> */}
					<FadeInAndDownText>
						<Typography sx={bigText} align="center">Hi. I'm Tristan.</Typography>
						<Typography sx={bigText} align="center">A frontend developer.</Typography>
					</FadeInAndDownText>
					
					<FadeInAndDownText>
						<Typography sx={{...regularText, ...subCopyStyle}} align="center">I'm passionate about creating powerful backend systems and dynamic frontend user interfaces.</Typography>
					</FadeInAndDownText>

					<FadeInAndDownText>
						<Stack direction="row" alignItems="center" justifyContent="center" spacing={3}>
							<Link sx={linkText} href="https://github.com/tris-n" target="_blank" rel="noopener noreferrer">Github<LaunchIcon sx={iconStyle} /></Link>
							<Link sx={linkText} href="https://www.linkedin.com/in/tris-n/" target="_blank" rel="noopener noreferrer">LinkedIn<LaunchIcon sx={iconStyle} /></Link>
							<Link sx={linkText} href="mailto:trisn.work@gmail.com" target="_blank" rel="noopener noreferrer">Email<LaunchIcon sx={iconStyle} /></Link>
						</Stack>
					</FadeInAndDownText>
				{/* </StaggerChildren> */}

				<Box sx={starStyle}></Box>
			</Stack>
		</Container>
	)
}
export default Welcome