// React
import { useEffect, useState } from 'react'

// Material UI
import {Box, Container, Link, Stack, } from '@mui/material'
import { styled } from '@mui/material/styles'

// Helpers
import { smoothScrollTo } from '../helpers/smoothScroll'



// Custom Components
const NavBar = styled(Stack)({
	backgroundColor: "#FFFFFF90",
	backdropFilter: "blur(10px)",
	paddingTop: "10px",
	paddingBottom: "10px",
	borderRadius: "26px",
	position: "fixed",
	top: "20px",
	zIndex: "99999",
})

const IndividualLink = styled(Link)({
	fontSize: "16px", 
	color: "var(--primary-color)",
	textDecoration: "none",
	padding: "0px",
	zIndex: "2",
	cursor: "pointer",
})

const IndividualLinkContainer = styled(Box)(({ theme }) => ({
	height: "32px",
	padding: "0px 20px",
	margin: "0px 15px",
	borderRadius: "16px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",

	// [theme.breakpoints.only('mobile')]: {
	// 	padding: "0px 15px",
	// 	margin: "0px 8px",
	// },
}))

const Slider = styled(Box)({
	position: "absolute",
	top: "0px",
	left: "0px",
	height: "0px",
	
	padding: "0px 20px",
	margin: "0px 15px",
	borderRadius: "16px",
	transition: "all 0.5s",
})


// Content
const navList = [
	{
		name: "Home",
		url: "#home",
	},
	{
		name: "Work",
		url: "#work",
	},
	{
		name: "About",
		url: "#about",
	},
	// {
	// 	name: "Contact",
	// 	url: "#contact",
	// },
]









// Nav
const Nav = ({activeSection}) => {

	// State
	const [selectedElement, setSelectedElement] = useState(null)

	const [sliderCSS, setSliderCSS] = useState({
		top: "10px",
		// left: {tablet: "0px", mobile: "-8px"},
		left: "0px",
		height: "32px",
		// width: {tablet: "83px", mobile: "73px"},
		width: "83px",
		backgroundColor: "#FFFFFF90",
	})


	// Update the slider
	const updateSlider = (element) => {

		if (element) {
			setSliderCSS({
				...sliderCSS,
				left: element.offsetLeft - 15,
				width: element.clientWidth,
			})
		}
	}


	// Handle Click
	const handleClick = (event, url, delay) => {
		const element = event.currentTarget
		setSelectedElement(element)
		// updateSlider(element)
		smoothScrollTo(url, delay)
	}


	// Handle Resize
	const handleResize = () => {
		if (selectedElement) {
			updateSlider(selectedElement)
		}
	}

	// Handle inView
	const handleInView = (navInView) => {
		setSelectedElement(navInView)
		updateSlider(navInView)
	}

	// check if the window has been resized and resize the slider
	useEffect(() => {
		window.addEventListener('resize', handleResize)

		// cleanup
		return () => {
			window.removeEventListener('resize', handleResize)
		}

	}, [selectedElement])

	// When the activeSection changes, setSelectedElement and updateSlider
	useEffect(() => {
		// console.log(`active section firing, ${activeSection}`)
		if (activeSection === "home") {
			handleInView(document.getElementById("Home"))
		} else if (activeSection === "BugTrackerX" || activeSection === "HomeFinder" || activeSection === "StudioPM") {
			handleInView(document.getElementById("Work"))
		} else if (activeSection === "about") {
			handleInView(document.getElementById("About"))
		}
	}, [activeSection])


	
	return (
		<Container maxWidth="desktop" id="home">
			
			<Stack direction="row" justifyContent="center">

				<NavBar direction="row">

					{navList.map((nav) => (
						// <IndividualLink href={nav.url} key={nav.name} >
						<IndividualLink key={nav.name} >
							<IndividualLinkContainer 
								id={nav.name} 
								onClick={(event) => handleClick(event, nav.url)} 
							>
								{nav.name}
							</IndividualLinkContainer>
						</IndividualLink>
					))}

					<Slider sx={sliderCSS} />

				</NavBar>

			</Stack>

			<div style={{height: "92px"}}></div>

		</Container>
	)
}
export default Nav