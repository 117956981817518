export const smoothScrollTo = (anchor) => {

	const targetElement = document.querySelector(anchor)
	if (!targetElement) {
		console.error('Target element not found:', anchor)
		return
	}

	const topOffset = targetElement.getBoundingClientRect().top + window.scrollY

	window.scrollTo({
		top: topOffset - 90,
		behavior: 'smooth'
	})
	
}