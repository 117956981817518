// Material UI
import { Box, Stack, Typography } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// Custom styles
import { h5style } from '../../../theme/customStyles'


const TitleBar = ({projectName, closeModal}) => {

	const CloseButton = () => {
		return (
			<Box onClick={()=> closeModal()} sx={{cursor: "pointer"}}>
				<HighlightOffIcon fontSize="large" />
			</Box>
		)
	}

	return (
		<Box sx={{marginBottom: "10px"}} >
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Box sx={{display: {mobile: "none", tablet: "initial"}}}><Typography sx={h5style}>{projectName} - Quick Tour</Typography></Box>
				<Box sx={{display: {mobile: "initial", tablet: "none"}}}><Typography sx={h5style}>{projectName}</Typography><Typography sx={h5style}>Quick Tour</Typography></Box>
				<CloseButton />
			</Stack>
			<hr />
		</Box>
	)
}
export default TitleBar