// Material UI
import { Box, Container, Stack, Typography } from "@mui/material"

// Framer Motion
import StaggerChildren from './FramerComponents/StaggerChildren'
import FadeInText from './FramerComponents/FadeInText'

// Data
import { aboutMeConcise, aboutMeVerbose } from '../data/copy'

// Custom styles
import { regularText, letsWorkText } from '../theme/customStyles'

const letWorkBoxStyle = {
	width: {
		mobile: "91%",
		tablet: "90%", 
		laptop: "60%", 
	}, 
	marginTop: {
		mobile: "40px",
		tablet: "60px", 
		laptop: "60px", 
	},
	paddingBottom: {
		mobile: "40px",
		tablet: "60px",
		laptop: "80px",
	},
}



const About = () => {

	return (
		<Container maxWidth="desktop" align="center" id="about" sx={{scrollMarginTop: "90px"}}>

			<Box sx={letWorkBoxStyle} align="left">

				{/* INTRO */}
				<Typography sx={letsWorkText} align="center">Let's Work Together</Typography>

				<StaggerChildren>

					{aboutMeConcise.intro.map((text, index) => (
						<FadeInText key={index}>
							<Typography 
								align="left"
								sx={{...regularText, marginBottom: "20px"}}>
									{text}
							</Typography>
						</FadeInText>
					))}

					{/* OFFERINGS */}
					<ul className="offeringsList">
						{aboutMeConcise.offerings.map((offer, index) => (
							<FadeInText key={index}>
								<li key={offer.title}>
									<Typography sx={regularText}>
										<span className="offeringStyle">{offer.title}:</span> {offer.description}
									</Typography>
								</li>
							</FadeInText>
						))}
					</ul>

					{/* OUTRO */}
					<FadeInText>
						<Typography sx={regularText}>
							{aboutMeConcise.outro} <a href="mailto:trisn.work@gmail.com">Email me!</a>
						</Typography>
					</FadeInText>

				</StaggerChildren>

			</Box>
		</Container>
	)
}
export default About