// Material UI
import { Box, Stack, Typography } from "@mui/material"

// Custom styles
import { tabText, activeTabText } from '../../theme/customStyles'

const tabBoxStyle = {
	marginTop: {
		mobile: "20px",
		tablet: "40px", 
		laptop: "30px", 
	},  
	borderRadius: {
		mobile: "20px 20px 0px 0px",
		tablet: "30px 30px 0px 0px", 
		laptop: "30px 30px 0px 0px", 
	}, 
	marginBottom: "5px", 
	cursor: "pointer",
}



// Tabs
const Tabs = ({info, selectedTab, setSelectedTab}) => {

	// Styles
	const tabStyle = {
		padding: {
			mobile: "0px 15px 0px 15px",
			tablet: "0px 25px 0px 25px",
			laptop: "0px 25px 0px 25px",
		},
		height: "70px",
		// width: "33%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	} 
	
	const activeTabStyle = {
		padding: {
			mobile: "0px 15px 0px 15px",
			tablet: "0px 25px 0px 25px",
			laptop: "0px 25px 0px 25px",
		},
		height: "70px",
		// width: "33%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderBottom: `10px solid ${info.selectedTab}`, 
		paddingTop: "10px !important",
	}

	// Handle Tab Selection
	const handleTabClick = (tab) => {
		setSelectedTab(tab)
	}
	


	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{...tabBoxStyle, backgroundColor: info.tabColor}}>
					
			<Box sx={selectedTab === "features" ? activeTabStyle : tabStyle} onClick={() => handleTabClick("features")}>
				<Typography sx={selectedTab === "features" ? activeTabText : tabText} align="center">Project Features</Typography>
			</Box>

			<Box sx={selectedTab === "role" ? activeTabStyle : tabStyle} onClick={() => handleTabClick("role")}>
				<Typography sx={selectedTab === "role" ? activeTabText : tabText} align="center">My Role</Typography>
			</Box>

			<Box sx={selectedTab === "challenges" ? activeTabStyle : tabStyle} onClick={() => handleTabClick("challenges")}>
				<Typography sx={selectedTab === "challenges" ? activeTabText : tabText} align="center">Challenges</Typography>
			</Box>

			{/* <Box sx={selectedTab === "tech" ? activeTabStyle : tabStyle} onClick={() => handleTabClick("tech")}>
				<Typography sx={selectedTab === "tech" ? activeTabText : tabText}>Tech</Typography>
			</Box> */}

		</Stack>
	)
}
export default Tabs