// Material UI
import { Container, Stack } from "@mui/material"

// Components
import Project from "./Project/Project"

// Data
import { projects } from '../data/projects_v4_2024'



const Portfolio = () => {

	return (
		<Container maxWidth="desktop" id="work" sx={{scrollMarginTop: "90px"}}>
			<Stack direction="column" spacing={10}>
				{projects.map((project) => (
					<Project info={project} key={project.projectName} />
				))}
			</Stack>
		</Container>
	)
}
export default Portfolio