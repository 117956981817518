// Material UI
import { Box, Stack, Typography } from "@mui/material"

// Framer Motion
import StaggerChildren from "../../FramerComponents/StaggerChildren"
import FadeInFeature from "../../FramerComponents/FadeInFeature"

// Custom styles
import { featureText, featureItemStyle, featureItemFirstStyle, featureItemLastStyle } from '../../../theme/customStyles'



const Learning = ({info, learning}) => {
	return (
		<>

			{learning.descriptions.map((description, index) => {
				const isFirstFeature = index === 0
				const isLastFeature = index === (learning.descriptions.length - 1);
				const moreThanOne = learning.descriptions.length > 1
				const marginTopValue = index === 0 ? "6px" : "5px";
				const stackStyle = isFirstFeature ? featureItemFirstStyle : isLastFeature ? featureItemLastStyle : featureItemStyle;

				if (description.steps && description.steps.length > 0) {
					
					return (
						<>
							{description.steps.map((step, stepIndex) => {

								const isFirstFeature = stepIndex === 0
								const isLastFeature = stepIndex === (description.steps.length - 1);
								const moreThanOne = description.steps.length.length > 1
								const marginTopValue = index === 0 ? "6px" : "5px";
								const stackStyle = isFirstFeature ? featureItemFirstStyle : isLastFeature ? featureItemLastStyle : featureItemStyle;

								return (
									
										<FadeInFeature key={stepIndex}>
											<Stack
												direction="row"
												alignItems="center"
												sx={{
												...stackStyle,
												backgroundColor: info.featureColor,
												marginTop: marginTopValue,
												}}
											>
												<Typography sx={featureText}>
													{stepIndex === 0 && <strong>Learning{moreThanOne && "s"}: </strong>}{step.description}
												</Typography>
											</Stack>
										</FadeInFeature>
									)}
								)
							}
						</>
					)

				} else {
					
					return (
						<FadeInFeature key={index}>
							<Stack
								direction="row"
								alignItems="center"
								sx={{
								...stackStyle,
								backgroundColor: info.featureColor,
								marginTop: marginTopValue,
								}}
							>
								<Typography sx={featureText}>
									{index === 0 && <strong>Learning{moreThanOne && "s"}:{description.steps?.length} </strong>}{description.text}
								</Typography>
							</Stack>
						</FadeInFeature>
					)

				}

				
			})}

		</>
	)
}
export default Learning